/* Container styles */
.homepage-container {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Ensures the container covers the viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  color: white;
  z-index: 1;
}

.homepage-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://www.themoors.com/wp/wp-content/uploads/veterans-day-a-time-to-honor-and-remember-min.jpg');
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  filter: brightness(0.7); /* Optional: Darkens the image for better text readability */
  z-index: -1;
}


/* Title and description styles */
.homepage-title {
  font-size: 3rem;
  margin: 0px 0 20px;
  color: white;
  font-weight: bold;
}

.what-we-do {
  font-size: 2rem;
  margin-bottom: 20px;
  color: white;
}

.homepage-description,
.homepage-statistic {
  font-size: 1.1rem;
  margin-bottom: 30px;
  line-height: 1.6;
  padding: 0 20px;
}

/* Link button styles */
.link-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 40px;
  gap: 20px;
}

.link-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  font-size: 1.2rem;
  color: white;
  background-color: #181818;
  border-radius: 20px;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.link-button:hover {
  background-color: #434343;
  transform: scale(1.05);
}

.link-icon {
  margin-bottom: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .homepage-title {
    font-size: 2.5rem;
  }

  .what-we-do {
    font-size: 1.5rem;
  }

  .homepage-description,
  .homepage-statistic {
    font-size: 1rem;
    padding: 0 10px;
  }

  .link-button {
    width: 200px;
    height: 200px;
    font-size: 1rem;
  }
}
