/* user story 2: give our website a universal color theme */
/* starting styles css file */

:root {
    --primary-color: #005d05; /* Bootstrap primary color */
    --secondary-color: #6c757d; /* Bootstrap secondary color */
    --success-color: #28a745;
    --danger-color: #dc3545;
    --warning-color: #ffc107;
    --info-color: #17a2b8;
    --light-color: #f8f9fa;
    --dark-color: #343a40;
    --body-bg: #f8f9fa; /* Body background color */
    --card-bg: #f8f9fa; /* Card background color */
    --card-text-color: #292e32; /* Text color in cards */
    --btn-bg: #f8f9fa; /* Button background color */
    --btn-text-color: #ffffff; /* Button text color */
  }
  
  body {
    background-color: var(--body-bg);
    color: var(--dark-color);
  }
  
  .card {
    background-color: var(--card-bg);
    color: var(--card-text-color);
  }
  
  .btn-custom {
    background-color: var(--btn-bg);
    color: var(--btn-text-color);
  }
  
  .sortable {
    cursor: pointer;
  }
  
  .sortable:hover {
    background-color: #5b5b5b; /* Light gray background */
    text-decoration: underline; /* Underline the text */
  }

  .small-search-bar { /* css for jobs, facilites, forms search bar */
    width: 250px;
    display: inline-block;
    text-align: left;
  }